<template>
  <div>
    <v-row dense>
      <v-col
        cols="12"
        sm="4"
        md="null"
        xl="null"
        v-for="(img, index) in gallery"
        :key="img.src"
        align="center"
        justify="center"
      >
        <v-img
          :src="img.thumbnail"
          max-width="300"
          :aspect-ratio="16 / 9"
          @click="showImage(index)"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="primary">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>

    <v-overlay v-model="bg" @click.native="bg = !bg"></v-overlay>

    <div class="lightbox" v-if="bg">
      <v-btn icon large class="lightbox__close white" @click.stop="bg = !bg">
        <v-icon color="error">{{ closeIcon }}</v-icon>
      </v-btn>

      <v-btn icon large class="lightbox__prev white" @click="prev">
        <v-icon color="primary">{{ prevIcon }}</v-icon>
      </v-btn>

      <v-btn icon large class="lightbox__next white" @click="next">
        <v-icon color="primary">{{ nextIcon }}</v-icon>
      </v-btn>

      <!-- key is needed to reshow the lazy-src on src change (https://github.com/vuetifyjs/vuetify/issues/6633) => fiexd in vuetify 3 -->
      <v-img
        class="lightbox__image"
        contain
        :src="gallery[activeImage].src"
        :key="gallery[activeImage].src"
        :lazy-src="gallery[activeImage].thumbnail"
        @error="errorOccured = true"
        @loadstart="errorOccured = false"
        min-height="32px"
        min-width="32px"
        width="1000px"
        height="665px"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
              v-if="!errorOccured"
            >
            </v-progress-circular>
            <v-icon large v-else color="error"> {{ errorIcon }} </v-icon>
          </v-row>
        </template>
      </v-img>

      <v-alert
        v-if="gallery[activeImage].description && !errorOccured"
        dense
        class="lightbox__caption mb-0"
      >
        {{ gallery[activeImage].description }}
      </v-alert>

      <v-alert
        v-else-if="errorOccured"
        dense
        type="error"
        :icon="errorIcon"
        class="lightbox__caption mb-0"
      >
        {{ imageErrorMessage }}
      </v-alert>
    </div>
  </div>
</template>

<script>
/*
  TODO
  - error currently is not used => add timer to remove progress-circular, but simply use error to set variable
  - add option to decide if go in circles through images or not
  - error message should be a prop with default value
  - following values must be set by the user:
  -- options of v-col
  -- v-img for thumbnail
  -- error message for thumbnail (use in tooltip)
  - return event for error, click on next, prev, close
  - provide slots for the following:
  -- prev, next, close, error, description
  - add slots for useful parts
*/
export default {
  name: 'lightbox-gallery',
  data () {
    return {
      bg: false,
      errorOccured: false,
      activeImage: null
    }
  },
  props: {
    gallery: Array,
    closeIcon: {
      type: String,
      default: 'close'
    },
    prevIcon: {
      type: String,
      default: 'arrow_back'
    },
    nextIcon: {
      type: String,
      default: 'arrow_forward'
    },
    errorIcon: {
      type: String,
      default: 'report'
    },
    imageErrorMessage: {
      type: String,
      default: 'Unable to load image'
    },
    thumbnailErrorMessage: {
      type: String,
      default: 'Unable to load thumbnail'
    }
  },
  methods: {
    showImage (index) {
      this.activeImage = index
      this.bg = !this.bg
    },
    next () {
      if (this.gallery.length - 1 > this.activeImage) {
        this.activeImage++
      } else {
        this.activeImage = 0
      }
      this.errorOccured = false
    },
    prev () {
      if (this.activeImage > 0) {
        this.activeImage--
      } else {
        this.activeImage = this.gallery.length - 1
      }
      this.errorOccured = false
    }
  },
  watch: {
    bg (val) {
      if (!val) {
        this.errorOccured = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lightbox {
  &__bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 100;
  }

  &__close {
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 200;
  }

  &__prev {
    position: fixed;
    left: 20px;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 200;
  }

  &__next {
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 200;
  }

  &__image {
    position: fixed;
    z-index: 100;
    left: 50%;
    top: 50%;
    max-width: 100%;
    max-height: 90%;
    transform: translate(-50%, -50%);
  }

  &__caption {
    position: fixed;
    z-index: 200;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, 5%);
  }
}
</style>
