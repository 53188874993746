<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-h4">{{ title }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <h3 class="text-h5 pb-4" id="Preise">Preise</h3>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Zeitraum</th>
                        <th class="text-left">Saison</th>
                        <th class="text-right">Preis*</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="period in activePeriods" :key="period.start">
                        <td>
                          {{
                            new Date(period.start).toLocaleDateString(
                              "de-DE",
                              localeOptions
                            )
                          }}
                          -
                          {{
                            new Date(period.end).toLocaleDateString(
                              "de-DE",
                              localeOptions
                            )
                          }}
                        </td>
                        <td>{{ period.saison }}</td>
                        <td class="text-right">{{ period.price }}€</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-row>
                  <v-col cols="12" class="text-center grey--text">
                    <small>
                      * Die Preise verstehen sich pro Übernachtung incl.
                      Endreinigung, Wäsche und W-LAN. Die Kurabgabe wird
                      zusätzlich pro Übernachtung und Person gezahlt.
                    </small>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <h3 class="text-h5 pb-4" id="Galerie">Galerie</h3>

                <image-gallery :gallery="images"></image-gallery>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import imageGalleryVue from './image-gallery.vue'
export default {
  name: 'appartment-card',
  data () {
    return {
      localeOptions: {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }
    }
  },
  props: {
    title: String,
    images: Array,
    seasonalPrices: Array
  },
  computed: {
    activePeriods () {
      var current = new Date()
      current.setHours(0, 0, 0)
      return this.seasonalPrices.filter(price => {
        return new Date(price.end).valueOf() >= current.valueOf()
      })
    }
  },
  components: {
    'image-gallery': imageGalleryVue
  }
}
</script>

<style lang="scss" scoped>
</style>
